import { Route, Routes } from 'react-router-dom';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import Maintenance from './pages/Maintenance';
import Accueil from './pages/Accueil';
import PermisMoto from './pages/PermisMoto';
import PermisVoiture from './pages/PermisVoiture';
import VotreCpf from './pages/VotreCpf';
import Financements from './pages/Financements';
import Documents from './pages/Documents';

function App() {
  return (  
      <Routes>
        <Route path="/" element={<Accueil />}/>
        <Route path="/permis-voiture" element={<PermisVoiture />}/>
        <Route path="/permis-moto" element={<PermisMoto />}/>
        <Route path="/votre-cpf" element={<VotreCpf />}/>
        <Route path="/financements" element={<Financements />}/>
        <Route path="/documents" element={<Documents />}/>
      </Routes>
    );
}

export default App;
