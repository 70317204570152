import { BadgeVoiture } from "./BadgeVoiture"

export function CardPermisVoiture({titre, description, image, badge, children}) {
    return (
        <>
            <div className="py-4 rounded-lg">
                <div className="shadow-lg group max-w-sm flex justify-center items-center mx-auto content-div scale-100 hover:scale-105 duration-300 ease-in-out">
                    <div className="bg-gray-100 min-h-[724px]">
                        <main>
                            <img className="w-full" src={image} alt="peugeot 208"/>
                            <div className="px-4 py-4">
                                <div className="font-bold text-xl mb-2">
                                    {titre}
                                </div>
                                <p className="text-gray-700 text-base">
                                    {description}
                                </p>
                            </div>
                            <div className="px-4 py-4">
                                {
                                    badge.map((text, key) => {
                                        return (
                                            <BadgeVoiture key={key} text={text} />
                                        )
                                    })
                                }
                            </div>
                        </main>
                        <footer className="fixed bottom-0">
                            {children}
                        </footer>
                    </div>
                </div>
            </div>
        </>
    )
}