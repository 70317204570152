import React, { useState } from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

import Cors from '../components/Navbar/Cors';
import ReactCardFlip from 'react-card-flip';

import { CardPermisVoiture } from '../components/CardPermisVoiture/CardPermisVoiture';
import { CardPermisBack } from '../components/CardPermisBack/CardPermisBack';

import forfaitB from "../asset/forfait/forfaitB.png";
import forfaitAAC from "../asset/forfait/forfaitAAC.png";
import forfaitCS from "../asset/forfait/forfaitCS.png";

export default function PermisVoiture () {

    const permisVoitures = [
        {
            titre: "Permis B Traditionnel",
            description: "Le permis B permet de conduire une voiture ou une camionnette. Il permet aussi de conduire, sous conditions, un camping-car, une moto légère (scooter, moto 125) ou un tracteur.",
            image: forfaitB,
            badge: [
                "À partir de 17 ans",
                "Période probatoire de 3 ans"
            ],
            descriptionBis:  
            <>
                <h2 className="text-base text-bold">Le formation <b>boite manuelle</b> comprend:</h2>
                <ul className="list-disc py-4 px-4">
                    <li>Inscription (au fichier national des permis de conduire)</li>
                    <li>Kit Pédagogique (fiche de suivi, livret d'apprentissage)</li>
                    <li>Forfait code salle + internet 4 mois</li>
                    <li>1 heure d'évaluation de départ</li>
                    <li>20 heures de conduite obligatoires</li>
                    <li>Frais d'accompagnement à l'examen</li>
                </ul>
                <h2 className="text-base text-bold">Le formation <b>boite automatique</b> comprend:</h2>
                <ul className="list-disc py-4 px-4">
                    <li>Inscription (au fichier national des permis de conduire)</li>
                    <li>Kit Pédagogique (fiche de suivi, livret d'apprentissage)</li>
                    <li>Forfait code salle + internet 4 mois</li>
                    <li>1 heure d'évaluation de départ</li>
                    <li>13 heures de conduite obligatoires</li>
                    <li>Frais d'accompagnement à l'examen</li>
                </ul>
            </>
        },
        {
            titre: "Permis B Conduite supervisée",
            description: "Vous êtes âgé de 18 ans ou plus et vous souhaitez réussir votre examen de conduire mais l’expérience vous manque? Notre auto-école vous propose la formule conduite supervisée qui vous aide à perfectionner vos acquis au volant avant le passage de votre épreuve. En tant qu’apprenti conducteur, vous devez être accompagné d’un adulte titulaire du permis B depuis au moins 5 ans .",
            image: forfaitCS,
            badge: [
                "À partir de 17 ans",
                "Durée de 3 mois minimum",
                "Période probatoire de 3 ans"
            ],
            descriptionBis:   
            <>
                <h2 className="text-base text-bold">Le formation <b>boite manuelle</b> comprend:</h2>
                <ul className="list-disc py-4 px-4">
                    <li>Inscription (au fichier national des permis de conduire)</li>
                    <li>Kit Pédagogique (fiche de suivi, livret d'apprentissage)</li>
                    <li>Forfait code salle + internet 4 mois</li>
                    <li>1 heure d'évaluation de départ</li>
                    <li>20 heures de conduite obligatoires</li>
                    <li>2 rendez-vous pédagogique</li>
                    <li>Guide de l'accompagnateur</li>
                    <li>Frais d'accompagnement à l'examen</li>
                </ul>
                <h2 className="text-base text-bold">Le formation <b>boite automatique</b> comprend:</h2>
                <ul className="list-disc py-4 px-4">
                    <li>Inscription (au fichier national des permis de conduire)</li>
                    <li>Kit Pédagogique (fiche de suivi, livret d'apprentissage)</li>
                    <li>Forfait code salle + internet 4 mois</li>
                    <li>1 heure d'évaluation de départ</li>
                    <li>13 heures de conduite obligatoires</li>
                    <li>2 rendez-vous pédagogique</li>
                    <li>Guide de l'accompagnateur</li>
                    <li>Frais d'accompagnement à l'examen</li>
                </ul>
            </>
        },
        {
            titre: "Permis B Conduite accompagnée",
            description: "Destinée aux jeunes de 15 ans ou plus, la conduite accompagnée ou l'apprentissage anticipé de la conduite (AAC), vous permet d'acquérir une expérience de la conduite avant de passer le permis de conduire. En tant qu’apprenti conducteur, vous devez être accompagné d’un adulte titulaire du permis B depuis au moins 5 ans.",
            image: forfaitAAC,
            badge: [
                "À partir de 15 ans",
                "Durée d'un an minimum",
                "3 000 km à parcourir",
                "Période probatoire de 2 ans"
            ],
            descriptionBis:    
            <>
                <h2 className="text-base text-bold">Le formation <b>boite manuelle</b> comprend:</h2>
                <ul className="list-disc py-4 px-4">
                    <li>Inscription (au fichier national des permis de conduire)</li>
                    <li>Kit Pédagogique (fiche de suivi, livret d'apprentissage)</li>
                    <li>Forfait code salle + internet 4 mois</li>
                    <li>1 heure d'évaluation de départ</li>
                    <li>20 heures de conduite obligatoires</li>
                    <li>2 rendez-vous pédagogique</li>
                    <li>Guide de l'accompagnateur</li>
                    <li>Frais d'accompagnement à l'examen</li>
                </ul>
                <h2 className="text-base text-bold">Le formation <b>boite automatique</b> comprend:</h2>
                <ul className="list-disc py-4 px-4">
                    <li>Inscription (au fichier national des permis de conduire)</li>
                    <li>Kit Pédagogique (fiche de suivi, livret d'apprentissage)</li>
                    <li>Forfait code salle + internet 4 mois</li>
                    <li>1 heure d'évaluation de départ</li>
                    <li>13 heures de conduite obligatoires</li>
                    <li>2 rendez-vous pédagogique</li>
                    <li>Guide de l'accompagnateur</li>
                    <li>Frais d'accompagnement à l'examen</li>
                </ul>
            </>
        }
    ];

    const documents = [
        "1 photocopie recto verso de la carte d’identité en cours de validité",
        "1 photocopie de la carte de séjour pour les nationalités étrangères",
        "1 justificatif de domicile de moins de 3 mois",
        "1 attestation d'hébergement écrite, datée et signée à la main",
        "1 photocopie de l'ASSR2",
        "1 photocopie de la carte d’identité du père ou de la mère pour les mineurs",
        "1 photocopie de l’attestation de recensement pour les 16-18 ans",
        "1 photocopie de l’attestation JAPD pour les plus de 18 ans",
        "4 photos agrées ANTS",
        "4 enveloppes timbrées"
    ];

    const nomBoutonRetourner = "Cliquer pour voir la formule";
    const nomBoutonRetournerBack = "Cliquer pour voir la formation";

    const [isFlipped, setIsFlipped ] = useState(0);
    const [isFlippedCS, setIsFlippedCS ] = useState(0);
    const [isFlippedAAC, setIsFlippedAAC ] = useState(0);

    const handleClick = (e) => {
        e.preventDefault();
        setIsFlipped(!isFlipped);
    };
    
    const handleClickCS = (e) => {
        e.preventDefault();
        setIsFlippedCS(!isFlippedCS);
    };
    
    const handleClickAAC = (e) => {
        e.preventDefault();
        setIsFlippedAAC(!isFlippedAAC);
    };

    document.title = "Plaisir Auto Ecole - Voiture"

    return (
        <div className='background-plaisir'>
            <Cors>
                <div className="text-bold flex justify-between">
                    <h1 className='text-5xl'>Permis Voiture</h1>
                    <a 
                        href='https://docs.google.com/forms/d/e/1FAIpQLSfqXeyicxtW6e_CgJdIdVpF8Rbpr4YvE_o7aVgn-NlkiK9ciA/viewform'
                        target='_blank'
                        rel="noreferrer"
                        className='bg-red-700 text-white hover:underline px-3 py-2 rounded-md font-medium text-center grid content-center'
                    >
                        Demande d’inscription
                    </a>
                </div>
                <div className="md:flex py-4">
                    <Swiper
                        modules={[Pagination, Autoplay]}
                        slidesPerView={1}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            900: {
                                slidesPerView: 2,
                            },
                            1200: {
                                slidesPerView: 3,
                            },
                        }}
                        autoplay= {{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        className="relative w-full mb-6"
                    >
                        <SwiperSlide tag="article" className="px-4">
                            <ReactCardFlip isFlipped={isFlipped}>
                                <CardPermisVoiture 
                                    titre={permisVoitures[0].titre} 
                                    description={permisVoitures[0].description} 
                                    image={permisVoitures[0].image}  
                                    badge={permisVoitures[0].badge}
                                >
                                    <button className="px-2 py-2 underline" onClick={handleClick}>{nomBoutonRetourner}</button>
                                </CardPermisVoiture>
                                <CardPermisBack
                                    titre={permisVoitures[0].titre} 
                                    content={permisVoitures[0].descriptionBis} 
                                >
                                    <button className="px-2 py-2 underline" onClick={handleClick}>{nomBoutonRetournerBack}</button>
                                </CardPermisBack>
                            </ReactCardFlip>
                        </SwiperSlide>
                        <SwiperSlide tag="article" className="px-4">
                            <ReactCardFlip isFlipped={isFlippedCS}>
                                <CardPermisVoiture 
                                    titre={permisVoitures[1].titre} 
                                    description={permisVoitures[1].description} 
                                    image={permisVoitures[1].image}  
                                    badge={permisVoitures[1].badge}
                                >
                                    <button className="px-2 py-2 underline" onClick={handleClickCS}>{nomBoutonRetourner}</button>
                                </CardPermisVoiture>
                                <CardPermisBack
                                    titre={permisVoitures[1].titre} 
                                    content={permisVoitures[1].descriptionBis} 
                                >
                                    <button className="px-2 py-2 underline" onClick={handleClickCS}>{nomBoutonRetournerBack}</button>
                                </CardPermisBack>
                            </ReactCardFlip>
                        </SwiperSlide>
                        <SwiperSlide tag="article" className="px-4">
                            <ReactCardFlip isFlipped={isFlippedAAC}>
                                <CardPermisVoiture 
                                    titre={permisVoitures[2].titre} 
                                    description={permisVoitures[2].description} 
                                    image={permisVoitures[2].image}  
                                    badge={permisVoitures[2].badge}
                                >
                                    <button className="px-2 py-2 underline" onClick={handleClickAAC}>{nomBoutonRetourner}</button>
                                </CardPermisVoiture>
                                <CardPermisBack
                                    titre={permisVoitures[2].titre} 
                                    content={permisVoitures[2].descriptionBis} 
                                >
                                    <button className="px-2 py-2 underline" onClick={handleClickAAC}>{nomBoutonRetournerBack}</button>
                                </CardPermisBack>
                            </ReactCardFlip>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <a 
                    href='programme-b.pdf'
                    target='_blank'
                    rel="noreferrer"
                    className='bg-red-700 text-white hover:underline px-3 py-2 rounded-md font-medium text-center grid content-center my-8'
                >
                    Programme de formation détaillé
                </a>
                <div className="bg-gray-100 rounded-xl">
                    <div className="mx-4 p-2">
                        <div className="font-bold text-xl mb-2">Documents à fournir pour l'inscription :</div>
                        <ul className="list-disc">
                            {
                                documents.map((value, key) => {
                                    return (
                                        <li key={key} className='mt-2'>{value}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </Cors>
        </div>
    )
}