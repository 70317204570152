import Lottie from "react-lottie-player";

import phone from "../../lotties/phone.json";

export function Phone() {
    return (
        <>
            <a href="tel:0130553163">
                <span className="flex hover:underline decoration-red-700 decoration-solid underline-offset-1 decoration-2 px-3 py-2 justify-center">
                    <Lottie
                        loop
                        animationData={phone}
                        play
                        style={{ width: 25, height: 25 }}
                    />
                    01 30 55 31 63
                </span>
            </a>
        </>
    );
}