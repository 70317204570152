export function BadgeMoto({text}) { 
    return (
        <>  
            <span 
                className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full"
            >
                {text}
            </span>
        </>
    )
}