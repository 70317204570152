import React from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

import Cors from '../components/Navbar/Cors';
import { Review } from '../components/Review/Review';

import instagram from "../asset/instagram.svg";
import facebook from "../asset/facebook.svg";

import codeRousseau from "../asset/code-rousseau.png";

import generateStyleSheet from '../lib/generateStyleSheet'
import injectCss from '../lib/injectCss'

import caroussel1 from "../asset/caroussel/caroussel1.jpeg";
import caroussel2 from "../asset/caroussel/caroussel2.jpeg";
import caroussel3 from "../asset/caroussel/caroussel3.jpeg";
import caroussel4 from "../asset/caroussel/caroussel4.jpeg";
import caroussel5 from "../asset/caroussel/caroussel5.jpeg";
import caroussel6 from "../asset/caroussel/caroussel6.jpeg";

const NAME = 'ReactBackgroundSlider'

export default function Accueil () {

    const textIntro = `Adeline et son équipe mettent à votre service leur savoir-faire et leurs 15 années d'expérience pour vous former à la conduite automobile et la sécurité routière. Ils seront à l'écoute de vos besoins, de vos difficultés et assurent un accompagnement personnalisé tout au long de votre formation. Bénéficiez de leur professionnalisme pour adopter une conduite sûre et responsable.`;

    const textPermisMotoPiste = "Piste privée à 3 minutes de l'auto école pour les formations motos.";

    const coordonnees = [
        {
            label: "Adresse",
            text: "51 Bis Rue de la Gare, 78370 Plaisir"
        },
        {
            label: "Télephone",
            text: "01 30 55 31 63"
        },
        {
            label: "Email",
            text: "plaisirautoecole@gmail.com"
        }
    ];

    const resaux = [
        {
            // Instagram
            name: "plaisir_autoecole_",
            image: instagram,
            link: "https://www.instagram.com/plaisir.autoecole/"
        },
        {
            // Facebook
            name: "plaisir_autoecole_",
            image: facebook,
            link: "https://www.facebook.com/people/Plaisir-Auto-Ecole/100054403454303/"
        },/*
        {
            // Tiktok
            name: "@plaisir_auto_ecole",
            image: tiktok,
            link: "https://www.tiktok.com/@plaisir_auto_ecole"
        },
        {
            // Snapchat
            name: "plaisirautoecole",
            image: snapchat,
            link: ""
        }*/
    ];

    const caroussel = [
        caroussel1,
        caroussel2,
        caroussel3,
        caroussel4,
        caroussel5,
        caroussel6
    ];

    const reviews = [
		{
			name: "aieb",
			date: "15/05/2024",
			note: 5,
			text:
			<>
				xcellente auto-école ! Les moniteurs Samy, Sébastien et Thomas sont vraiment top. Ils sont patients, pédagogues et très professionnels. Mention spéciale à Adeline et Sébastien pour leur accueil chaleureux. Grâce à eux, j'ai pu obtenir mon permis. Je recommande vivement !
			</>
		},
		{
			name: "Manon Penso",
			date: "30/05/2024",
			note: 5,
			text:
			<>
				Excellente auto école !
				Personnel accueillant et bienveillant, Adeline est au toooooop elle explique tout ce que nous avons besoin de savoir.
				Mention spéciale aux moniteurs dévoués et professionnels, MERCI 🙏
			</>
		},
		{
			name: "Charlotte Cardon",
			date: "24/05/2024",
			note: 5,
			text:
			<>
				Une auto école incroyable avec un personnel très compétent et toujours disponible pour ses élèves !!
				Je recommande cette auto école les yeux fermés, vous en resortirez avec un permis en poche c’est garanti !
			</>
		},
		{
			name: "Anthony Vicari",
			date: "30/05/2024",
			note: 5,
			text:
			<>
				Une équipe formidable !
				Adeline est toujours à l’écoute et est super arrangeante pour nous placer des heures en fonctions de nos disponibilités.
				Les moniteurs sont également au top !
				Je suis sidéré de pourvoir lire certains avis négatif quand je vois à quel point tout est parfait !
				On dit que la perfection n’existe pas…et bien j’ai trouvé l’exception qui confirme cette règle en faisant confiance à plaisir auto-école
			</>
		},
        {
            name: "Sebastien Marin",
            date: "15/05/2024",
            note: 5,
            text:
            <>
                Une auto école incroyable, des personnes super sympa très à l’écoute qui mettent énormément en confiance.
				Un grand merci à eux 🫶

				Je veux riens savoir c’est meilleur auto école.
				Vous pouvez y aller les yeux fermés
            </>
        },
        {
            name: "Nino_ MilletBreuzin",
            date: "22/01/2024",
            note: 5,
            text:
            <>
                Après m’être inscrit en début novembre, je pensais avoir mon permis au plus tôt en mars/avril.
                Résultat, une équipe à l’écoute des demandes et les moniteurs très pédagogues. Merci à toute l’équipe; samy, seb, Adeline et surtout Thomas de m’avoir donner la chance de passer mon permis et de l’avoir du premier coup au bout de 20h le 19 janvier.
                Je recommande fortement car une auto école aussi réactive et impliqué est rare dans le 78.
                Encore un grand merci.
            </>
        },
        {
            name: "selena baci",
            date: "15/11/2023",
            note: 5,
            text:
            <>
                Auto école au top, moniteurs à l’écoute, encourageant, et surtout ne nous lâche pas pour qu’on réussissent. Je l’ai enfin obtenue après 4 défaites grâce à eux. Merci encore pour tout.
            </>
        },
        {
            name: "Junior Konate",
            date: "01/02/2024",
            note: 5,
            text:
            <>
                Équipe formidable, et investi pour ces élèves je recommande fortement !! Je vous recommande vivement Thomas !!! Bonne chance #Junior
            </>
        },
        {
            name: "Ledron Marie",
            date: "01/11/2023",
            note: 5,
            text:
            <>
                Super Auto école merci à mes moniteur pour mon apprentissage permis obtenu du premier coup je vous recommande.
            </>
        },
        {
            name: "Soraya Chennaoui",
            date: "01/02/2023",
            note: 5,
            text:
            <>
                Super auto-école avec de super moniteurs, j'ai eu mon permis du premier coup grâce à eux. Une super pédagogie et très à l'écoute. C'était un plaisir d'apprendre avec eux ! Je conseille fortement ^^
            </>
        },
        {
            name: "Sadam Safi",
            date: "01/02/2024",
            note: 5,
            text:
            <>
                Merci beaucoup c’est le top du top ,
                Meilleure auto-école je vous la recommande!
            </>
        }
    ]

    React.useEffect(() => {
      injectCss(
        generateStyleSheet({
          imagesCount: caroussel.length,
          duration: 5,
          transition: 1
        }),
        NAME
      )
    })

    document.title = "Plaisir Auto Ecole - Accueil"

    return (
        <div>
            <Cors>
                <div id={NAME}>
                    {caroussel.map((img, key) =>
                        <figure key={key}
                            style={{
                                maxHeight: '50%',
                                backgroundImage: `url(${img})`,
                                animationDelay: `${(5 + 1) * key}s`
                            }}
                        />
                    )}
                    <div className="lg:text-6xl md:text-4xl text-3xl text-center py-4 leading-loose my-2 mx-4 text-black h-1/2" style={{minHeight: "42vh"}}/>
                </div>
                <div className="rounded-xl py-4 px-4 mt-4">
                    <h1 className="font-bold text-xl text-center">
                        Plaisir Auto Ecole 
                    </h1>
                </div>
                <div className="rounded-xl">
                    <p className="text-xl text-center py-4 leading-loose my-2 mx-4">
                        {textIntro}
                    </p>
                    <p className="text-red-500 text-base text-center font-bold leading-loose pb-4 mb-4 mx-4">
                        {textPermisMotoPiste.toUpperCase()}
                    </p>
                </div>
                <div className="lg:flex">
                    <div className="max-w-auto overflow-hidden shadow-lg pt-4 bg-gray-100 rounded-xl">
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl">Coordonnées</div>
                            {
                                coordonnees.map((data, key) => {
                                        return (
                                            <div key={key} className="py-2 px-2 md:flex">
                                                <span className="underline">{data.label}</span>
                                                <span className="pl-2">{data.text}</span>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </div>
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2">Réseaux sociaux</div>
                            <ul className="list-none">
                                {
                                    resaux.map((data, key) => {
                                            return (
                                                <li key={key} className="py-1 hover:bg-red-300 rounded hover:rounded-lg">
                                                    <a href={data.link} target="_blank" rel="noreferrer">
                                                           <span className="flex items-center">
                                                            <img className="w-12 h-12" src={data.image} alt={data.name}/>
                                                            <div className="text-left">{data.name}</div>
                                                        </span>
                                                    </a>
                                                </li>
                                            )
                                        }
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="relative flex-auto md:px-4">
                        <iframe
                            title="google-map"
                            className="h-full w-full aspect-auto" 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2626.3999887235614!2d1.9386049771597067!3d48.83150867132859!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e685185ddda029%3A0xe91eb7c56a2171b1!2sPlaisir%20Auto%20Ecole!5e0!3m2!1sfr!2sfr!4v1706983425971!5m2!1sfr!2sfr" 
                            allowFullScreen="" 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                    <div className=" overflow-hidden shadow-lg pt-4 bg-gray-100 rounded-xl">
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2">Horaires heure de conduite</div>
                            <table className="table-auto border-separate [border-spacing:0.75rem]">
                                <tbody>
                                    <tr>
                                        <td colSpan="3">
                                            Les heures de conduite sont de 8h à 19h du lundi au samedi.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="font-bold text-xl mb-2">Horaires d'ouverture code et bureau </div>
                            <table className="table-auto border-separate [border-spacing:0.75rem]">
                                <tbody>
                                    <tr>
                                        <td className="underline">Lundi</td>
                                        <td>Fermé</td>
                                    </tr>
                                    <tr>
                                        <td className="underline">Mardi</td>
                                        <td>10:00 - 12:00</td>
                                        <td>15:00 - 18:00</td>
                                    </tr>
                                    <tr>
                                        <td className="underline">Mercredi</td>
                                        <td>10:00 - 12:00</td>
                                        <td>15:00 - 18:00</td>
                                    </tr>
                                    <tr>
                                        <td className="underline">Jeudi</td>
                                        <td>10:00 - 12:00</td>
                                        <td>15:00 - 18:00</td>
                                    </tr>
                                    <tr>
                                        <td className="underline">Vendredi</td>
                                        <td>10:00 - 12:00</td>
                                        <td>15:00 - 18:00</td>
                                    </tr>
                                    <tr>
                                        <td className="underline">Samedi</td>
                                        <td>10:00 - 13h00</td>
                                    </tr>
                                    <tr>
                                        <td className="underline">Dimanche</td>
                                        <td>Fermé</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> 
                <div className="bg-gray-100 rounded-xl py-4 px-4 mt-4">
                    <a href="https://public.codesrousseau.fr/" target="_blank" rel="noreferrer">
                        <img className="w-auto h-24 mx-auto pb-4" src={codeRousseau} alt="code rousseau"/>
                        <div className="pl-4">
                            <div className="font-bold text-xl mb-2 text-center">
                                Une pédagogie en ligne
                            </div>
                            <p className="text-base text-center py-4 leading-loose my-4 mx-4">
                                Nous mettons à la disposition de nos élèves un outil pédagogique connecté. En effet la plateforme Code Rousseau permet à chaque élève de se connecter pour s'entrainer au Code de la route avant l'examen.
                            </p>
                        </div>
                    </a>
                </div>
                <div className="rounded-xl py-4 px-4 mt-4">
                    <div className="pl-4">
                        <div className="font-bold text-xl mb-4 text-center">
                            Livre d'or
                        </div>
                        <Swiper
                            modules={[Pagination, Autoplay]}
                            slidesPerView={1}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                875: {
                                    slidesPerView: 2,
                                },
                                1300: {
                                    slidesPerView: 3,
                                },
                            }}
                            autoplay= {{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            className="relative w-full mb-6"
                        >
                            {
                                reviews.map((review, key) => {
                                        return (
                                            <SwiperSlide tag="article" className="px-4" key={key}>
                                                <Review name={review.name} date={review.date} text={review.text} note={review.note}/>
                                            </SwiperSlide>
                                        )
                                    }
                                )
                            }
                        </Swiper>
                    </div>
                </div>
            </Cors>
        </div>
    )
}