import { Link } from "./Link";

export function AllLink() {

    const classLink = "text-sm grid content-center hover:underline hover:decoration-red-700 hover:decoration-solid hover:underline-offset-1 hover:decoration-2";

    return (
        <>
            <Link label="Permis Voiture" url="/permis-voiture" classNom={classLink}/>
            <Link label="Permis Moto" url="/permis-moto" classNom={classLink}/>
            <Link label="Votre CPF" url="/votre-cpf" classNom={classLink}/>
            <Link label="Financements" url="/financements" classNom={classLink}/>
            <Link label="Documents associés" url="/documents" classNom={classLink}/>
        </>
    );
}

export function AllLinkDropDown() {

    const classDropdown = "text-sm text-center grid content-center hover:underline hover:decoration-red-700 hover:decoration-solid hover:underline-offset-1 hover:decoration-2";

    return (
        <>
            <Link label="Permis Voiture" url="/permis-voiture" classNom={classDropdown}/>
            <Link label="Permis Moto" url="/permis-moto" classNom={classDropdown}/>
            <Link label="Votre CPF" url="/votre-cpf" classNom={classDropdown}/>
            <Link label="Financements" url="/financements" classNom={classDropdown}/>
            <Link label="Documents associés" url="/documents" classNom={classDropdown}/>
        </>
    );
}