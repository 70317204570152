import React from 'react';
import Swal from 'sweetalert2';
import Cors from '../components/Navbar/Cors';

import engagement from "../asset/engagement.png";
import moncpf from "../asset/moncpf.png";

export default function VotreCpf () {

  const onClick = () => {
    Swal.fire({
        title:"Processus de certification en cours", 
        confirmButtonText: "Compris"
    });
  };

  Swal.fire({
      text:"Notre auto-école est inscrite dans le processus de certification Qualiopi. Les informations relatives aux financements par le CPF et les OPCO seront applicables uniquement après l’obtention de cette certification. Nous nous engageons alors à mettre les informations à jour dans les plus courts délais.", 
      confirmButtonText: "Compris"
  });

  document.title = "Plaisir Auto Ecole - Votre CPF"

  return (
    <div className='background-plaisir'>
        <Cors>
            <div className="bg-gray-100 rounded-xl lg:flex">
              <div className='flex-none'>
                <img className="h-full w-auto" src={engagement} alt="engagement"/>
              </div>
              <div className='flex-initial'>
                <p className="text-md text-center py-4 leading-loose m-4">
                  Notre auto-école s’inscrit dans une démarche qualité visant l’obtention de la certification Qualiopi. L’obtention de cette
                  certification attestera de la qualité de notre processus de mise en œuvre d’une formation. Il permettra aux élèves ainsi qu’aux
                  entreprises de bénéficier des fonds publics pour un financement total ou partiel de leurs formations (CPF – OPCO – Autres). Les
                  conditions d’accès à ces financements seront présentées de manière personnalisée et sur demande.
                  <button 
                      onClick={onClick}
                      href='#'
                      target='_blank'
                      rel="noreferrer"
                      className='bg-red-700 text-white hover:underline px-3 mt-4 rounded-md font-medium text-center w-fit'
                  >
                      Consultez notre certificat
                  </button>
                </p>
              </div>
            </div>
            <div className="bg-gray-100 rounded-xl py-4 px-4 mt-4">
                <img className="w-auto h-24 mx-auto pb-4" src={moncpf} alt="mon cpf"/>
                <div className="pl-4">
                  <div className="px-4 py-4 text-center">
                      <p className="text-gray-700 text-base">
                        Vous souhaitez passer votre permis
                        de conduire ? Bonne nouvelle,
                        l’apprentissage de la conduite fait
                        partie des formations que vous
                        pouvez financer avec Mon compte
                        formation.
                      </p>
                      <p className="text-gray-700 text-base my-8">
                        Exigé pour l’exercice de nombreux
                        métiers, le permis B est un réel atout
                        sur votre CV et un passeport pour
                        l’autonomie dans votre vie personnelle comme professionnelle.
                      </p>
                      <p className="text-gray-700 text-base">
                        Pour plus d’informations, suivez 
                        <a
                          href='https://www.moncompteformation.gouv.fr/espace-public/tout-savoir-sur-le-permis-de-conduire'
                          target='blank'
                          rel="noreferrer"
                          className='text-blue-500 hover:underline'
                        > ce lien.</a>
                      </p>
                  </div>
                </div>
            </div>
        </Cors>
    </div>
  )
}