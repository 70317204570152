import React from 'react';

import Swal from 'sweetalert2';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import Cors from '../components/Navbar/Cors';

import engagement from "../asset/engagement.png";
import idf from "../asset/idf.png";
import employeur from "../asset/employeur.png";
import pole from "../asset/pole-emploie.svg";

export default function Financements () {
  const onClick = () => {
    Swal.fire({
        title:"Processus de certification en cours", 
        confirmButtonText: "Compris"
    });
  };

  const onClick2 = () => {
    Swal.fire({
      title:"La certification Qualiopi est nécessaire pour accéder aux financements par les OPCO et autres financeurs. Nous nous engageons à mettre à jour les informations dans les meilleurs délais", 
      confirmButtonText: `
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfqXeyicxtW6e_CgJdIdVpF8Rbpr4YvE_o7aVgn-NlkiK9ciA/viewform" target="blank" rel="noreferrer">Compris</a>
      `,
    });
  };

  Swal.fire({
      text:"Notre auto-école est inscrite dans le processus de certification Qualiopi. Les informations relatives aux financements par le CPF et les OPCO seront applicables uniquement après l’obtention de cette certification. Nous nous engageons alors à mettre les informations à jour dans les plus courts délais.", 
      confirmButtonText: "Compris"
  });

  document.title = "Plaisir Auto Ecole - Financements"

  return (
    <div className='background-plaisir'>
        <Cors>
            <div className="bg-gray-100 rounded-xl lg:flex">
              <div className='flex-none'>
                <img className="h-full w-auto" src={engagement} alt="engagement"/>
              </div>
              <div className='flex-initial'>
                <p className="text-md text-center py-4 leading-loose m-4">
                  Notre auto-école s’inscrit dans une démarche qualité visant l’obtention de la certification Qualiopi. L’obtention de cette
                  certification attestera de la qualité de notre processus de mise en œuvre d’une formation. Il permettra aux élèves ainsi qu’aux
                  entreprises de bénéficier des fonds publics pour un financement total ou partiel de leurs formations (CPF – OPCO – Autres). Les
                  conditions d’accès à ces financements seront présentées de manière personnalisée et sur demande.
                  <button 
                      onClick={onClick}
                      href='#'
                      target='_blank'
                      rel="noreferrer"
                      className='bg-red-700 text-white hover:underline px-3 mt-4 rounded-md font-medium text-center w-fit'
                  >
                      Consultez notre certificat
                  </button>
                </p>
              </div>
            </div>
            <Swiper
                modules={[Pagination]}
                slidesPerView={1}
                pagination={{
                clickable: true,
                }}
                breakpoints={{
                    875: {
                        slidesPerView: 2,
                    },
                    1200: {
                        slidesPerView: 3,
                    },
                }}
                className="relative w-full mb-6"
            >
              <SwiperSlide tag="article" className="">
                <div className="py-4 px-4 rounded-lg">
                    <div className="shadow-lg group max-w-sm flex justify-center items-center mx-auto content-div">
                        <div className="bg-gray-100 rounded-lg min-h-[620px]">
                          <div className='flex justify-center items-center'>
                            <img className="w-auto h-44" src={idf} alt="moto de l'auto école"/>
                          </div>
                            <div className="px-4 py-4">
                                <p className="text-gray-700 text-base">
                                  <b>PERMIS B</b> La Région Île-de-France
                                  finance jusqu'à 1.300 euros le permis
                                  B des Franciliens de 18 à 25 ans en
                                  formation ou en recherche d'emploi.
                                  Lancée en mars 2021, cette aide
                                  présente une nouveauté de taille
                                  depuis septembre 2022 : les
                                  bénéficiaires n'ont plus à avancer
                                  d'argent, les auto-écoles étant payées
                                  directement. Pour plus
                                  d’informations, suivez 
                                  <a
                                    href='https://www.iledefrance.fr/aides-et-appels-a-projets/cheque-permis-de-conduire-pour-les-jeunes-en-insertion'
                                    target='blank'
                                    rel="noreferrer"
                                    className='text-blue-500 hover:underline'
                                  > ce lien.</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
              </SwiperSlide>
              <SwiperSlide tag="article" className="">
                <div className="py-4 px-4 rounded-lg">
                    <div className="shadow-lg group max-w-sm flex justify-center items-center mx-auto content-div">
                        <div className="bg-gray-100 rounded-lg min-h-[620px]">
                            <div className='flex justify-center items-center'>
                              <img className="w-auto h-44 px-8" src={pole} alt="moto de l'auto école"/>
                            </div>
                            <div className="px-4 py-4">
                                <p className="text-gray-700 text-base">
                                  Vous pouvez bénéficier de l’aide du
                                  <b> permis de conduire B</b> sous réserve
                                  de remplir certaines conditions
                                  d’inscription et de ressources. Vous
                                  devez être inscrit à Pôle emploi au
                                  moins 6 mois de manière continue et
                                  être disponible pour occuper un
                                  emploi. Vous ne devez pas être
                                  indemnisé au titre d’une allocation
                                  chômage, ou être indemnisé au titre
                                  d'une autre allocation dont le montant
                                  est inférieur ou égal à celui de
                                  l'allocation de retour à l’emploi (ARE)
                                  minimale. Les autres conditions sont
                                  accessibles en suivant 
                                  <a
                                    href='https://www.pole-emploi.fr/candidat/vos-recherches/les-aides-financieres/aide-a-lobtention-du-permis-de-c.html'
                                    target='blank'
                                    rel="noreferrer"
                                    className='text-blue-500 hover:underline'
                                  > ce lien </a>
                                  ou sur demande auprès de notre bureau.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
              </SwiperSlide>
              <SwiperSlide tag="article" className="">
                <div className="py-4 px-4 rounded-lg">
                  <div className="shadow-lg group max-w-sm flex justify-center items-center mx-auto content-div">
                      <div className="bg-gray-100 rounded-lg min-h-[620px]">
                          <div className='flex justify-center items-center'>
                            <img className="w-auto h-44" src={employeur} alt="moto de l'auto école"/>
                          </div>
                          <div className="px-4 py-4">
                              <p className="text-gray-700 text-base pb-4">
                                Notre auto-école est en mesure de
                                vous proposer une réflexion autour de
                                vos besoins spécifiques en formation
                                et sensibilisation de vos équipes,
                                notamment lorsque celle-ci sont
                                utilisatrices de véhicule d’entreprise
                                dans le cadre de leur fonction. Notre
                                engagement qualité obtenu pourrait
                                vous permettre de bénéficier d’un
                                financement par votre OPCO ou par
                                d’autres modes de financements au
                                titre de la formation professionnelle.
                              </p>
                              <div className='flex justify-center items-center'>
                                <button 
                                    onClick={onClick2}
                                    className='bg-red-700 text-white hover:underline px-3 py-2 my-4 rounded-md font-medium text-center w-fit'
                                >
                                    Exprimer votre besoin
                                </button>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
        </Cors>
    </div>
  )
}