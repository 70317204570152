export function Stars({note}) { 
  return (
    <div className="flex items-center space-x-1 rtl:space-x-reverse">
      {[...Array(5)].map((_, i) => (
        <svg key={i} className={`w-5 h-5 fill-current ${i < note ? 'text-yellow-500' : 'text-gray-300'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M10 1l2.6 6.8H20l-5 4.2 1.8 7L10 15.4 3.2 18l1.8-7-5-4.2h7.4L10 1z"/>
        </svg>
      ))}
    </div>
  )
}