import avatar from "../../asset/default-avatar.png";
import { Stars } from "../Stars/Stars";

export function Review({name, date, text, note}) { 
  return (
    <figure className="flex flex-col items-center justify-center px-8 pt-8 pb-0 text-center bg-white border border-gray-200 rounded-lg shadow">
      <figcaption className="flex justify-between">
          <img className="rounded-full w-9 h-9" src={avatar} alt="profile picture"/>
          <div className="space-y-0.5 font-medium text-left rtl:text-right ms-3">
              <div>{name}</div>
              <div className="text-sm text-gray-500">{date}</div>
              <Stars note={note} />
          </div>
      </figcaption>
      <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500">
          <p className="my-4">{text}</p>
      </blockquote>
    </figure>
  )
}