import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { AllLink, AllLinkDropDown } from "./AllLink";

import { ReactComponent as Logo } from "../../asset/plaisir_favicon.svg";
import { Phone } from "../Phone/Phone";

export default function Cors ({ children }) {

    const [isOpen, setIsOpen] = useState(false);

    return (
    <div>
        <nav className="bg-gray-100 bg-opacity-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <a href="/">
                        <div className="flex items-center" >
                            <div className="flex-shrink-0">
                                <Logo className="h-12 w-12"/>
                            </div>
                            <span className="self-center text-lg font-semibold whitespace-nowrap ml-5">
                                PLAISIR AUTO ECOLE
                            </span>
                        </div>
                    </a>
                    <div className="hidden sc:block">
                        <div className="ml-10 flex space-x-4 justify-between">
                            <AllLink/>
                        </div>
                    </div>
                    <div className="hidden sc:block text-center">
                        <Phone />
                    </div>
                    <div className="-mr-2 flex sc:hidden">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            type="button"
                            className="bg-gray-400 inline-flex items-center justify-center p-2 rounded-md hover:text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                        >
                        {
                            isOpen ? (
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )
                        }
                        </button>
                    </div>
                </div>
            </div>
            <Transition
                show={isOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="sc:hidden" id="mobile-menu">
                    <div className="px-2 pt-2 pb-3 space-y-4 sm:px-3">
                        <AllLinkDropDown/>
                        <Phone />
                    </div>
                </div>
            </Transition>
            </nav>
            <main>
                <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 ">
                    {children}
                </div>
            </main>
    </div>
    );
}