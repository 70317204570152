import { NavLink } from "react-router-dom";

export function Link({label, url, classNom}) {

    const classInactive = classNom;
    const classActive = "text-sm text-center grid content-center underline decoration-red-700 decoration-solid underline-offset-1 decoration-2";

    return (
        <NavLink
            to={url} 
            className={
                ({ isActive }) => isActive ? classActive : classInactive
            }
        >
            {label}
        </NavLink>
    );
}