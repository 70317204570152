import { BadgeMoto } from "./BadgeMoto"

export function CardPermisMoto({titre, description, image, badge, programme, programmeB, children}) {
    return (
        <>
        <div className="py-4 rounded-lg">
            <div className="shadow-lg group max-w-sm flex justify-center items-center mx-auto content-div scale-100 hover:scale-105 duration-300 ease-in-out">
                <div className="bg-gray-100 min-h-[830px]">
                    <main>
                        <img className="w-full" src={image} alt="moto de l'auto école"/>
                        <div className="px-4 py-4">
                            <div className="font-bold text-xl mb-2">
                                {titre}
                            </div>
                            <p className="text-gray-700 text-base">
                                {description}
                            </p>
                        </div>
                        {
                            programme && (
                                <a 
                                    href={programme}
                                    target='_blank'
                                    rel="noreferrer"
                                    className='bg-red-700 text-white hover:underline p-1 rounded-md text-sm font-medium text-center grid content-center mx-4'
                                >
                                    Programme de formation - {titre.split('-')[0]}
                                </a>
                            )
                        }
                        {
                            programmeB && (
                                <a 
                                    href={programmeB}
                                    target='_blank'
                                    rel="noreferrer"
                                    className='bg-red-700 text-white hover:underline p-1 rounded-md text-sm font-medium text-center grid content-center mx-4 mt-4'
                                >
                                    Programme de formation - {titre.split('-')[1]}
                                </a>
                            )
                        }
                        <div className="px-4 py-4">
                            {
                                badge.map((text, key) => {
                                    return (
                                        <BadgeMoto key={key} text={text} />
                                    )
                                })
                            }
                        </div>
                    </main>
                    <footer className="fixed bottom-0">
                        {children}
                    </footer>
                </div>
            </div>
        </div>
        </>
    )
}