import React from 'react';
import Swal from 'sweetalert2';
import Cors from '../components/Navbar/Cors';

export default function Documents () {
	const documents = [
		{ titre: "Demande d’inscription", url: "https://docs.google.com/forms/d/e/1FAIpQLSfqXeyicxtW6e_CgJdIdVpF8Rbpr4YvE_o7aVgn-NlkiK9ciA/viewform" },
		{ titre: "Règlement intérieur", url: "reglement-interieur.pdf" },
		{ titre: "CGV", url: "" },
		{ titre: "CGU Code Rousseau", url: "https://eleve.codesrousseau.fr/vues/acces_eleve/avis-cgu.html" },
		{ titre: "Organigramme et Contacts", url: "organigramme.pdf" },
		{ titre: "Certificat Qualiopi", url: "" },
		{ titre: "Formulaire de réclamation", url: "https://docs.google.com/forms/d/e/1FAIpQLSfD_v6eM4Y9iSam9flYqp3JwE_vCHLpbidRwGo3cltM9gT5QA/viewform" }
	];

	const onClick = () => {
		Swal.fire({
				title:"Processus de certification en cours", 
				confirmButtonText: "Compris"
		});
	};

	document.title = "Plaisir Auto Ecole - Documents"

	return (
		<div className='background-plaisir'>
			<Cors>
				<h1 className='text-5xl text-center mb-8'>Documents associés</h1>
				<div className='flex justify-center items-center'>
					<div className="bg-gray-100 rounded-xl">
						<div className="m-8">
							<ul className="text-lg">
								{
									documents.map((value, key) => {
										if (value.url === "") {
											return (
												<li key={key} className="">
													<button 
														onClick={onClick}
														className="block w-full cursor-pointer rounded-lg p-4 hover:underline hover:text-gray-500"
													>
														{value.titre}
													</button>
												</li>
											)
										} else {
											return (
												<li key={key} className="">
													<a 
															target='_blank'
															rel="noreferrer"
															className="text-center block w-full cursor-pointer rounded-lg p-4 hover:underline hover:text-gray-500"
															href={value.url}
													>
															{value.titre}
													</a>
												</li>
											)
										}
									})
								}
							</ul>
						</div>
					</div>
				</div>
			</Cors>
		</div>
  	)
}